
.hero-section{
  height: 80vh;
  display: flex;
  align-items: center;
}
  /* .landing-slider {
    border: none !important; 
    background: none !important; 
    box-shadow: none !important; 
    padding-top: 8em !important;
} */


.carousel-indicators [data-bs-target] {
  background-color: #6000d9 !important;
}

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: -8.8rem !important;
    margin-left: 15%;
}
  
@keyframes backgroundZoom {
    from {
      background-size: 150%; 
    }
    to {
      background-size: 100%; 
    }
  }
  
  /* Zoom-in class for background */
  .hero-section.zoom-in {
    background-image: url(../../Images/kama-logo/kama-hero-bg-logo2.png); 
    animation: backgroundZoom 2s ease-in-out forwards;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
      background-position: center center; /* Center the background image */
      background-size: contain; /* Ensure the image scales correctly */
  }

  .hero-explore-btn {
    background: #D1D9FB;
    color: black;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }


  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .carousel-content{
    padding-bottom: 1em !important;
    padding-left: 0.2em !important;
    
  }

  .hero-header-heading {
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }


  .carousel-item.active .hero-header-heading {
    animation: fadeInUp 1s ease forwards;
  }

  .hero-header .hero-header-heading {
    color: #6000d9;
    margin: 0;
    padding: 0;
  }


  

  



  @media (max-width: 991px){
    .hero-section{
      height: 30vh;
    }

    .landing-slider{
      padding-top: 4em !important;
    }

    .carousel-indicators {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      padding: 0;
      margin-right: 15%;
      margin-bottom: -3.8rem !important;
      margin-left: 15%;
  }
    
  }


  @media (max-width: 480px) {
    .hero-section{
        height: 80vh;
    }

   
    .hero-section.zoom-in {
      background-image: url(../../Images/kama-logo/kama-hero-bg-logo2.png); 
      animation: backgroundZoom 2s ease-in-out forwards;
      background-repeat: no-repeat;
      background-position: center center; /* Center the background image */
      background-size: contain; /* Ensure the image scales correctly */
    }
  
    .carousel {
      border: none !important; /* Ensure no border is applied */
      background: none !important; /* Remove any background color */
      box-shadow: none !important; /* Remove shadow if any */
      padding-top: 24em !important;
      display: flex;
      justify-content: center; /* Center the carousel content horizontally */
      align-items: center; /* Center the carousel content vertically */
    }
  
    .carousel-inner {
      text-align: center; /* Center the content inside each carousel item */
    }
  
    .hero-header .hero-header-heading {
      font-size: 1.5em; /* Adjust the font size for smaller screens */
    }
  
    .carousel-content {
      padding-bottom: 1em !important;
      padding-left: 0.2em !important;
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-right: 15%;
        margin-bottom: -1rem !important;
        margin-left: 15%;
    }

    .hero-header-heading{
      padding-bottom: 0.3em !important;
    }
    .vanta-container {
      width: 100% !important ;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
  }
  }
  